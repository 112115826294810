var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "parentDiv",
      staticStyle: {
        padding: "10px",
        height: "inherit",
        width: "100%",
        "overflow-y": "auto",
      },
    },
    [_c("canvas", { attrs: { id: "tree" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }