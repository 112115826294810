var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "msaWrapper", staticClass: "msa-wrapper" },
    _vm._l(_vm.blockRanges, function (ref, i) {
      var start = ref[0]
      var end = ref[1]
      return _c(
        "div",
        { staticClass: "msa-block" },
        _vm._l(_vm.getEntryRanges(start, end), function (ref, j) {
          var name = ref.name
          var aa = ref.aa
          var ss = ref.ss
          var seqStart = ref.seqStart
          var css = ref.css
          return _c("div", { staticClass: "msa-row" }, [
            _c(
              "span",
              {
                staticClass: "header",
                style: _vm.headerStyle(j),
                on: {
                  click: function ($event) {
                    return _vm.handleClickHeader($event, j)
                  },
                },
              },
              [_vm._v(_vm._s(name.padStart(_vm.headerLen, " ")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "sequence-wrapper" }, [
              _c("span", { staticClass: "sequence", style: css }, [
                _vm._v(_vm._s(_vm.alphabet === "aa" ? aa : ss)),
              ]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm
                    .countSequence(aa, seqStart)
                    .toString()
                    .padStart(_vm.countLen, " ")
                )
              ),
            ]),
          ])
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }